FontAwesomeConfig = { searchPseudoElements: true };
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/brands';

jQuery(document).ready(function($) {

 /* ====================== REMOVE EMPTY P TAG ======================  */
    $('p').each(function() {
        var $this = $(this);
        if ($this.html().replace(/\s|&nbsp;/g, '').length == 0)
            $this.remove();
    });

/* ====================== INIT FUNCSTIONS ======================  */

/* ====================== WINDOW REISZE FUNCTION  ======================  */
  $(window).resize(function() {
  })
});
